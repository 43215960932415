import { ReactNode } from 'react'
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react'
import { Controller, UseControllerProps } from 'react-hook-form'
import { Errors } from './Errors'

const SelectInput = <T,>({
  control,
  rules,
  name,
  label,
  placeholder,
  options,
  children,
}: {
  label: string
  options: Record<string, string>
  placeholder?: string
  disabled?: boolean
  children?: ReactNode
} & UseControllerProps<T>) => {
  return (
    <IonItem>
      <IonLabel position="stacked">{label}</IonLabel>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({
          field: { onChange, onBlur, value, ref },
          formState: { errors },
        }) => (
          <>
            <IonSelect
              interface="popover"
              placeholder={placeholder}
              onIonChange={onChange}
              onIonBlur={onBlur}
              value={value}
            >
              {Object.entries(options).map(([key, value]) => (
                <IonSelectOption key={key} value={key}>
                  {value}
                </IonSelectOption>
              ))}
            </IonSelect>
            {children}
            <Errors errors={errors} name={name} />
          </>
        )}
      ></Controller>
    </IonItem>
  )
}

export default SelectInput
