import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonIcon,
} from '@ionic/react'
import { KeyboardEventHandler, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import pick from 'lodash/pick'
import { pencilOutline } from 'ionicons/icons'
import { postActivate, postRegister, errorFromResponse } from '../../lib/api'
import { descriptiveSignupError, reviewError } from '../../lib/errors'
import { trackAppEvent } from '../../lib/analytics'
import { useLoading } from '../../lib/providers/loading'
import { useAlert } from '../../lib/hooks/alert'
import { useLoginNavigation } from '../../lib/hooks/navigation'
import Header from '../../components/Header'
import { Callout, Warning } from '../../components/Callout'
import CustomerForm, {
  CustomerInputs,
  GENDERS,
  COUNTRIES,
} from '../../components/CustomerForm'
import PasswordInput from '../../components/inputs/PasswordInput'
import ToggleInput from '../../components/inputs/ToggleInput'
import { ContactSupport, RemoteButton } from '../../components/Content'
import { css } from '@emotion/css'

interface PasswordInputs {
  generatePassword: boolean
  password?: string
  passwordConfirmation?: string
}

type SignupMode = 'register' | 'activate'
type SignupInputs = CustomerInputs & PasswordInputs

interface ActivateError {
  code: string
  message: string
}

enum FormStep {
  Client = 1,
  Password,
}

const SignupPage: React.FC = () => {
  const { mode } = useParams<{ mode: SignupMode }>()

  const defaultInputs: SignupInputs = {
    number: '',
    firstName: '',
    lastName: '',
    email: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    terms: false,
    advertisement: false,
    generatePassword: true,
    password: '',
    passwordConfirmation: '',
  }

  const [step, setStep] = useState(FormStep.Client)
  const [error, setError] = useState<ActivateError | null>(null)
  const [activateInputs, setActivateInputs] =
    useState<SignupInputs>(defaultInputs)
  const [alert] = useAlert()
  const [navigateToLogin] = useLoginNavigation()
  const [, { start, stop }] = useLoading()

  const onContinueClient = (newInputs: CustomerInputs) => {
    const inputs = {
      ...activateInputs,
      ...newInputs,
    }
    setActivateInputs(inputs)
    setStep(FormStep.Password)
  }

  const onContinueConfirmation = (newInputs: PasswordInputs) => {
    const inputs = {
      ...activateInputs,
      ...newInputs,
    }
    setActivateInputs(inputs)
    onSubmit(inputs)
  }

  const onSubmit: SubmitHandler<SignupInputs> = async (data) => {
    const { number: clientId } = data
    start()
    const res =
      mode === 'register'
        ? await postRegister(normalize(data))
        : await postActivate(clientId, normalize(data))

    if (res.ok) {
      stop({ success: true })
      await navigateToLogin({
        message:
          mode === 'register'
            ? 'E-Mail-Adresse bestätigen und Registrierung abschließen.'
            : 'Aktivierung abgeschlossen.',
        params: { clientId },
      })
      // TODO: mode
      trackAppEvent('activate')
    } else {
      const error = await errorFromResponse(res)
      stop({ success: false })
      console.error(error)
      setError({
        code: error.errno ? `${error.errno}/${error.code}` : error.code,
        message: descriptiveSignupError(error.code, error.status),
      })
      setStep(FormStep.Client)
    }

    function normalize(data: SignupInputs) {
      let submitData: {
        customer: SignupInputs & { dateOfBirth: string }
        password?: string
      } = {
        customer: {
          ...data,
          dateOfBirth:
            dayjs(
              `${data.birthYear}-${data.birthMonth}-${data.birthDay}`
            ).format('YYYY-MM-DD') + 'T00:00:00',
        },
      }

      if (data.generatePassword === false) {
        submitData = { ...submitData, password: data.password }
      }

      return submitData
    }
  }

  const onError: SubmitErrorHandler<SignupInputs> = (errors) => {
    console.error(errors)
    alert(reviewError)
  }

  const onBack = () => {
    setStep(step - 1)
  }

  return (
    <IonPage>
      <IonHeader>
        <Header back={false} logo={true} user={false}></Header>
      </IonHeader>
      <IonContent fullscreen>
        <SignupForm
          hidden={step !== FormStep.Client}
          mode={mode}
          values={activateInputs}
          onContinue={onContinueClient}
          onError={onError}
        >
          {error && (
            <div className="ion-padding-top">
              {error.code !== 'EPASWEX' ? (
                <>
                  <Warning>{error.message}</Warning>
                  <p>
                    <small>
                      <ContactSupport
                        action="die Aktivierung"
                        review={error.code !== 'EREMOTE'}
                        error={error}
                      />
                    </small>
                  </p>
                </>
              ) : (
                <>
                  <Callout>{error.message}</Callout>
                  <IonButton
                    fill="outline"
                    expand="block"
                    size="small"
                    routerLink="/reset-password"
                    className={
                      'secondary ' +
                      css`
                        margin-top: var(--ion-padding);
                        margin-bottom: calc(var(--ion-padding) * 2);
                      `
                    }
                  >
                    Kennwort zurücksetzen
                  </IonButton>
                </>
              )}
            </div>
          )}
        </SignupForm>
        <SignupConfirmationForm
          hidden={step !== FormStep.Password}
          values={activateInputs}
          mode={mode}
          onContinue={onContinueConfirmation}
          onError={onError}
          onBack={onBack}
        ></SignupConfirmationForm>
        {/* HACK: Workaround keyboard being positioned over input field or submit button */}
        <div style={{ height: '50vh' }}></div>
      </IonContent>
    </IonPage>
  )
}

const SignupForm: React.FC<{
  hidden: boolean
  mode: SignupMode
  values: CustomerInputs
  onContinue: SubmitHandler<CustomerInputs>
  onError: SubmitErrorHandler<CustomerInputs>
}> = ({ children, hidden = false, mode, values, onContinue, onError }) => {
  const registerFields = {
    gender: true,
    firstName: true,
    lastName: true,
    street: true,
    zipCode: true,
    city: true,
    countryNumber: true,
    telephone: true,
    mobilePhone: true,
    email: true,
    birthDate: true,
    birthYear: true,
    birthMonth: true,
    birthDay: true,
    branchNumber: true,
    terms: true,
    advertisement: true,
  }

  const activateFields = {
    number: true,
    firstName: true,
    lastName: true,
    email: true,
    birthDate: true,
    birthYear: true,
    birthMonth: true,
    birthDay: true,
    advertisement: true,
  }

  const fields = mode === 'register' ? registerFields : activateFields

  return (
    <>
      <CustomerForm
        {...{
          hidden,
          fields,
          values,
          onSubmit: onContinue,
          onError,
          header: children,
        }}
      >
        <IonButton
          expand="block"
          fill="outline"
          type="submit"
          className="secondary mt-2"
        >
          Weiter
        </IonButton>
        <Link to="/login" className="alternate-action">
          Abbrechen
        </Link>
      </CustomerForm>
    </>
  )
}

const SignupConfirmationForm: React.FC<{
  hidden: boolean
  values: SignupInputs
  mode: SignupMode
  onContinue: SubmitHandler<PasswordInputs>
  onError: SubmitErrorHandler<PasswordInputs>
  onBack: () => void
}> = ({ hidden = false, values, mode, onContinue, onError, onBack }) => {
  const { control, getValues, watch, handleSubmit } = useForm<PasswordInputs>({
    defaultValues: pick(values, [
      'generatePassword',
      'password',
      'passwordConfirmation',
    ]),
    criteriaMode: 'all',
  })

  const watchGeneratePassword = watch('generatePassword')

  const formattedDateOfBirth = dayjs(
    `${values.birthYear}-${values.birthMonth}-${values.birthDay}`
  ).format('DD.MM.YYYY')

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onContinue, onError)()
    }
  }

  return (
    <form
      hidden={hidden}
      className="ion-padding"
      onSubmit={handleSubmit(onContinue, onError)}
      onKeyDown={handleKeyDown}
      noValidate
    >
      <div className="ion-padding-vertical">
        <Callout>
          Ist Ihre E-Mail Adresse korrekt? Ihre Zugangsdaten senden wir Ihnen
          per E-Mail.
        </Callout>
      </div>

      <table className="table col-2">
        <tbody>
          {/* https://stackoverflow.com/a/53519842 */}
          {values.number ? (
            <tr>
              <td className="label">Kundennummer</td>
              <td>{values.number}</td>
            </tr>
          ) : null}
          {values.gender ? (
            <tr>
              <td className="label">Anrede</td>
              <td>{GENDERS[values.gender]}</td>
            </tr>
          ) : null}
          {values.firstName && values.lastName ? (
            <tr>
              <td className="label">Name</td>
              <td>
                {values.firstName} {values.lastName}
              </td>
            </tr>
          ) : null}
          {values.street ? (
            <tr>
              <td className="label">Straße</td>
              <td>
                {values.street}
                <br />
              </td>
            </tr>
          ) : null}
          {values.zipCode && values.city ? (
            <tr>
              <td className="label">Stadt</td>
              <td>
                {values.zipCode} {values.city}
                <br />
              </td>
            </tr>
          ) : null}
          {values.countryNumber ? (
            <tr>
              <td className="label">Land</td>
              <td>{COUNTRIES[values.countryNumber]}</td>
            </tr>
          ) : null}
          {values.telephone ? (
            <tr>
              <td className="label">Telefon</td>
              <td>
                {values.telephone}
                <br />
              </td>
            </tr>
          ) : null}
          {values.mobilePhone ? (
            <tr>
              <td className="label">Mobil</td>
              <td>
                {values.mobilePhone}
                <br />
              </td>
            </tr>
          ) : null}
          {values.email ? (
            <tr>
              <td className="label">E-Mail</td>
              <td>
                <strong>{values.email}</strong>
                <br />
              </td>
            </tr>
          ) : null}
          {values.birthYear ? (
            <tr>
              <td className="label">Geburtsdatum</td>
              <td>{formattedDateOfBirth}</td>
            </tr>
          ) : null}
        </tbody>
      </table>

      <IonButton
        fill="outline"
        expand="block"
        className="secondary"
        onClick={onBack}
      >
        <IonIcon slot="start" icon={pencilOutline}></IonIcon>
        Bearbeiten
      </IonButton>
      <IonList lines="full" className="mt-2">
        <ToggleInput<PasswordInputs>
          {...{ control }}
          name="generatePassword"
          label="Zufälliges Kennwort"
        ></ToggleInput>
        {watchGeneratePassword === false && (
          <PasswordInput<PasswordInputs>
            {...{ control, getValues }}
            rules={{
              required: 'Kennwort benötigt',
              minLength: {
                value: 6,
                message: 'Kennwort zu kurz (mindestens 6 Zeichen)',
              },
            }}
            name="password"
            label="Kennwort"
            confirmation={true}
          ></PasswordInput>
        )}
        <RemoteButton expand="block" type="submit" className="primary mt-1">
          {mode === 'register' ? 'Registrieren' : 'Aktivieren'}
        </RemoteButton>
      </IonList>
    </form>
  )
}

export default SignupPage
